import { Button, Icon, Stack } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { NS_CONTENT } from 'constants/i18n';
import styles from './Navigation.module.scss';

type NavigationProps = {
  onLeftClick: () => void;
  onRightClick: () => void;
};

const Navigation = ({ onLeftClick, onRightClick }: NavigationProps) => {
  const { t } = useTranslation(NS_CONTENT);

  return (
    <Stack gap={3} type="horizontal" mainAxisAlign="spaceBetween">
      {/* TODO: Replace with IconButton from design system (@carvertical/ui) when available */}
      <Button
        variant="blue"
        className={styles.iconButton}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onLeftClick();
        }}
        aria-label={t('previousArticle')}
      >
        <Icon name="arrow-left" size="m" />
      </Button>

      <Button
        variant="blue"
        className={styles.iconButton}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onRightClick();
        }}
        aria-label={t('nextArticle')}
      >
        <Icon name="arrow-right" size="m" />
      </Button>
    </Stack>
  );
};

export { Navigation };
