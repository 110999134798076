import type { WithRequired } from '@carvertical/ui';
import { ROUTE_DOCUMENT_QUERY } from '../groq';
import { sanityClient } from '../api';
import { BLOG_DEFAULT_LOCALE, TYPES_WITH_ROUTES } from '../constants';
import { buildCollectionQuery, prepareQueryParams, prepareRouteQuery } from '../utils';
import type {
  BlogRouteDocumentResponse,
  GetCollectionProps,
  GetEntryProps,
  RouteDocument,
} from '../types';

const getRouteDocument: (
  pathSegments: string[],
  locale: string,
) => Promise<RouteDocument | null> = async (pathSegments, locale) => {
  const result = await sanityClient.fetch<BlogRouteDocumentResponse>(ROUTE_DOCUMENT_QUERY, {
    locale,
    pathname: `/${pathSegments.join('/')}`,
    typesWithRoutes: TYPES_WITH_ROUTES,
  });

  return result ? result.docForRoute : null;
};

const getEntry = <EntryType>({
  docId,
  locale = BLOG_DEFAULT_LOCALE,
  params: extraParams,
  query,
}: GetEntryProps) => {
  const preparedQuery = prepareRouteQuery(query);
  const params = prepareQueryParams({
    extraParams: { ...extraParams, docId },
    locale,
  });

  return sanityClient.fetch<EntryType>(preparedQuery, params);
};

const getCollection = <CollectionType>({
  locale,
  docId,
  options,
  extraQuery,
}: WithRequired<GetCollectionProps, 'docId'>) => {
  const query = buildCollectionQuery({ options, extraQuery });

  return getEntry<CollectionType>({
    query,
    params: {
      pageNum: options.pageNum ?? 1,
      entryDocTypes: options.entryDocTypes,
    },
    docId,
    locale,
  });
};

export { getCollection, getEntry, getRouteDocument };
