import { Badge, Grid, GridItem, Heading, Stack, Text } from '@carvertical/ui';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'components/common/Link';
import { extractFirstEntry } from '../../../../utils';
import { RoundedImage } from '../../../common/RoundedImage';
import { ArticleDate } from '../../ArticleDate';
import type { Article } from '../../../../types';
import { AuthorImage } from '../../AuthorImage';
import { Navigation } from './Navigation';
import styles from './FeaturedArticleCard.module.scss';

type FeaturedArticleCardProps = {
  article: Article;
  onLeftClick: () => void;
  onRightClick: () => void;
  navigable: boolean;
};

const ANIMATION_VARIANTS = {
  hiddenRight: {
    x: 20,
    opacity: 0,
  },
  hiddenLeft: {
    x: -20,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const FeaturedArticleCard = ({
  article,
  navigable,
  onLeftClick,
  onRightClick,
}: FeaturedArticleCardProps) => {
  const [direction, setDirection] = useState<'left' | 'right'>('right');
  const category = extractFirstEntry(article.categories);
  const author = extractFirstEntry(article.authors);

  return (
    <Link to={article.pathname} className={styles.root}>
      <Grid className={styles.wrapper} columnGap={{ mobileUp: 0, tabletPortraitUp: 4 }} rowGap={2}>
        <GridItem span={{ mobileUp: 12, tabletPortraitUp: 5, tabletLandscapeUp: 7 }}>
          <motion.div
            key={article.pathname}
            variants={ANIMATION_VARIANTS}
            initial={direction === 'right' ? 'hiddenRight' : 'hiddenLeft'}
            animate="visible"
            exit="exit"
          >
            <RoundedImage image={article.featuredImage} />
          </motion.div>
        </GridItem>
        <GridItem span={{ mobileUp: 12, tabletPortraitUp: 7, tabletLandscapeUp: 5 }}>
          <Stack
            gap={{ mobileUp: 3, tabletPortraitUp: 4 }}
            className={styles.content}
            crossAxisAlign="stretch"
          >
            <Stack type="horizontal" gap={2} crossAxisAlign="center">
              <Badge size="s">{category.title}</Badge>

              <ArticleDate date={article.publishedAt} />
            </Stack>

            <Heading as="h2" variant="l">
              {article.title}
            </Heading>

            <Stack
              className={styles.authorBlock}
              type="horizontal"
              gap={2}
              mainAxisAlign="spaceBetween"
              crossAxisAlign="center"
            >
              <Stack gap={2} type="horizontal" crossAxisAlign="center">
                <AuthorImage
                  image={author.image}
                  name={author.name}
                  size={48}
                  className={styles.authorImageWrapper}
                />

                <Text variant="s">{author.name}</Text>
              </Stack>

              {navigable && (
                <Navigation
                  onLeftClick={() => {
                    setDirection('left');
                    onLeftClick();
                  }}
                  onRightClick={() => {
                    setDirection('right');
                    onRightClick();
                  }}
                />
              )}
            </Stack>
          </Stack>
        </GridItem>
      </Grid>
    </Link>
  );
};

export { FeaturedArticleCard };
