import { useCallback, useState } from 'react';

type ValidIndexProps = {
  length: number;
  defaultIndex?: number;
  infinite?: boolean;
  onChange?: (index: number) => void;
};

// TODO: replace this hook with useValidIndex from @carvertical/ui
const useValidIndex = ({
  defaultIndex = 0,
  infinite = false,
  length,
  onChange,
}: ValidIndexProps) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);

  const updateIndex = useCallback(
    (index: number) => {
      setActiveIndex(index);
      onChange?.(index);
    },
    [onChange],
  );

  const goToIndex = useCallback(
    (newIndex: number) => {
      if (newIndex < 0) {
        if (infinite) {
          updateIndex(length - 1);
        }

        return;
      }

      if (newIndex > length - 1) {
        if (infinite) {
          updateIndex(0);
        }

        return;
      }

      updateIndex(newIndex);
    },
    [updateIndex, length, infinite],
  );

  const goNext = () => goToIndex(activeIndex + 1);
  const goPrev = () => goToIndex(activeIndex - 1);

  return {
    activeIndex,
    goNext,
    goPrev,
    goToIndex,
  };
};

export { useValidIndex };
export type { ValidIndexProps };
