import { useRouter } from 'next/router';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ENTRIES_PER_PAGE, INITIAL_ENTRIES_PER_PAGE } from '../constants';
import { getArticleCollection } from '../services/blog';
import { getBlogLocale } from '../utils';
import type { BlogRouteProps } from '../types';

const STARTING_PAGE = INITIAL_ENTRIES_PER_PAGE / ENTRIES_PER_PAGE + 1;

const useInfiniteArticles = (enabled: boolean) => {
  const { query } = useRouter();

  const { country, language } = query as BlogRouteProps['params'];

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery({
    enabled,
    queryKey: ['articles'],
    staleTime: Infinity,
    initialPageParam: STARTING_PAGE,
    queryFn: async ({ pageParam }) => {
      const locale = getBlogLocale({ country, language });

      const articles = await getArticleCollection({
        locales: [locale],
        options: {
          entriesPerPage: ENTRIES_PER_PAGE,
          pageNum: pageParam,
        },
      });

      return { ...articles, previousPageParam: pageParam };
    },
    getNextPageParam: ({ previousPageParam, totalEntriesCount = 0 }) => {
      const nextPage: number = previousPageParam + 1;

      if (previousPageParam * ENTRIES_PER_PAGE > totalEntriesCount) {
        return undefined;
      }

      return nextPage;
    },
  });

  const articles = data?.pages.flatMap((page) => page.entries).filter(Boolean);

  return {
    articles: articles || [],
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  };
};

export { useInfiniteArticles };
