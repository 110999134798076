
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[country]/[language]/blog",
      function () {
        return require("private-next-pages/[country]/[language]/blog/index.ts");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[country]/[language]/blog"])
      });
    }
  