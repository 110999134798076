import { useValidIndex } from '../../../../hooks';
import type { Article } from '../../../../types';
import { FeaturedArticleCard } from './FeaturedArticleCard';

type FeaturedArticlesCarouselProps = {
  articles: Article[];
};

const FeaturedArticlesCarousel = ({ articles }: FeaturedArticlesCarouselProps) => {
  const { activeIndex, goNext, goPrev } = useValidIndex({
    infinite: true,
    length: articles.length,
  });

  return (
    <FeaturedArticleCard
      article={articles[activeIndex]}
      onLeftClick={goPrev}
      onRightClick={goNext}
      navigable={articles.length > 1}
    />
  );
};

export { FeaturedArticlesCarousel };
