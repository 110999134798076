import {
  ALL_CATEGORIES_QUERY,
  ARTICLE_ROUTE_QUERY,
  BLOG_ARTICLE_CARD_FRAGMENT,
  BLOG_PATHS,
  BLOG_TAG_FRAGMENT,
  CATEGORY_BY_KEY_ROUTE_QUERY,
  CATEGORY_ROUTE_QUERY,
  TAG_BY_TITLE_QUERY,
} from '../groq';
import { sanityClient } from '../api';
import { BLOG_ROOT_PATH, ENTRIES_PER_PAGE, SINGLETON_DOCUMENT_IDS } from '../constants';
import {
  type Article,
  type ArticleCategory,
  type ArticleCollectionResponse,
  BlogDocumentType,
  type BlogIndexContext,
  type BlogPathContext,
  type BlogPathsResponse,
  type BlogRouteDataResponse,
  type GetCollectionProps,
  type GetEntryProps,
  type ResolvedBlogIndexData,
  type ResolvedBlogRouteData,
  type RouteDocument,
  type ArticleTag,
} from '../types';
import { blogIndexFilters, ORDER_BY_PUBLISH_DATE_DESC } from '../filters';
import { getBlogLocale, getLocalizedSingletonId } from '../utils';
import { getCollection, getEntry, getRouteDocument } from './generic';

type ResolveBlogRouteDataProps = {
  document: RouteDocument;
  request: {
    options?: Partial<GetCollectionProps['options']>;
  };
};

const getBlogPaths = () => sanityClient.fetch<BlogPathsResponse>(BLOG_PATHS);

const getArticle = ({ ...props }: Omit<GetEntryProps, 'query'>) =>
  getEntry<BlogRouteDataResponse<Article>>({
    query: ARTICLE_ROUTE_QUERY,
    ...props,
  });

const getArticleCategory = ({ ...props }: Omit<GetEntryProps, 'query'>) =>
  getEntry<BlogRouteDataResponse<ArticleCategory>>({
    query: CATEGORY_ROUTE_QUERY,
    ...props,
  });

const getArticleCategoryByKey = ({ ...props }: Omit<GetEntryProps, 'query'>) =>
  getEntry<BlogRouteDataResponse<ArticleCategory[]>>({
    query: CATEGORY_BY_KEY_ROUTE_QUERY,
    ...props,
  });

const getArticleTagByTitle = ({ ...props }: Omit<GetEntryProps, 'query'>) =>
  getEntry<BlogRouteDataResponse<ArticleTag[]>>({
    query: TAG_BY_TITLE_QUERY,
    ...props,
  });

const getAllCategories = ({ ...props }: Omit<GetEntryProps, 'query'>) =>
  getEntry<BlogRouteDataResponse<ArticleCategory[]>>({
    query: ALL_CATEGORIES_QUERY,
    ...props,
  });

const getArticleCollection = async ({
  locales,
  options: customOptions,
}: Omit<GetCollectionProps, 'options' | 'locale'> & {
  locales: string[];
  options?: Partial<GetCollectionProps['options']>;
}) => {
  const options: GetCollectionProps['options'] = {
    entryDocTypes: [BlogDocumentType.Article],
    entryQueryFragment: BLOG_ARTICLE_CARD_FRAGMENT,
    currentFilterQueryFragment: BLOG_TAG_FRAGMENT,
    entriesPerPage: ENTRIES_PER_PAGE,
    order: ORDER_BY_PUBLISH_DATE_DESC,
    ...customOptions,
    extraFilters: blogIndexFilters(locales, customOptions?.extraFilters),
  };

  return getCollection<ArticleCollectionResponse>({
    options,
    docId: getLocalizedSingletonId({ baseId: SINGLETON_DOCUMENT_IDS.blogIndex }, locales[0]),
  });
};

const getBlogIndexRouteData = async ({
  options,
  params,
}: BlogIndexContext & {
  options: Partial<GetCollectionProps['options']>;
}): Promise<ResolvedBlogIndexData> => {
  const locale = getBlogLocale({
    country: params!.country,
    language: params!.language,
  });

  const { entries, routeData, totalEntriesCount } = await getArticleCollection({
    locales: [locale],
    options,
  });

  const { routeData: categories } = await getAllCategories({ locale });

  return {
    totalEntriesCount,
    categories,
    routeData: {
      ...routeData,
      articles: entries,
    },
  };
};

const resolveBlogPathData = async (
  document: ResolveBlogRouteDataProps['document'],
  locale: string,
): Promise<BlogRouteDataResponse<Article | ArticleCategory> | null> => {
  const { id, type } = document;

  const props = {
    docId: id,
    locale,
  };

  if (type === BlogDocumentType.Article) {
    return getArticle(props);
  }

  if (type === BlogDocumentType.Category) {
    return getArticleCategory(props);
  }

  return null;
};

const getBlogPathData = async ({
  params,
}: BlogPathContext): Promise<ResolvedBlogRouteData<Article | ArticleCategory> | null> => {
  let document: RouteDocument | null = null;
  const { country, language, path } = params!;

  const locale = getBlogLocale({
    country,
    language,
  });

  const pathSegments = [BLOG_ROOT_PATH, ...path];

  document = await getRouteDocument(pathSegments, locale);

  if (!document) {
    return null;
  }

  const data = await resolveBlogPathData(document, locale);

  const { routeData: categories } = await getAllCategories({ locale });

  return data ? { routeData: data.routeData, categories } : null;
};

export {
  getAllCategories,
  getArticle,
  getArticleCategory,
  getArticleCollection,
  getBlogPaths,
  getArticleCategoryByKey,
  getBlogIndexRouteData,
  getBlogPathData,
  getArticleTagByTitle,
};
