import { z } from 'zod';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';

const searchBlockFormSchema = z.object({
  term: z.string().min(1, { message: ERROR_TRANSLATION_KEYS.required }),
});

type SearchBlockFormFields = z.infer<typeof searchBlockFormSchema>;

export { searchBlockFormSchema };
export type { SearchBlockFormFields };
