import { Text, Container, Stack, TagGroup, Tag } from '@carvertical/ui';
import cx from 'classnames';
import { useRef, useState } from 'react';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { Link } from 'components/common/Link';
import styles from './Submenu.module.scss';

type SubmenuLink = {
  link: string;
  children: React.ReactNode;
  active?: boolean;
  main?: boolean;
};

type SubmenuProps = {
  links: SubmenuLink[];
  actions?: React.ReactNode;
};

const SHADOW_MOBILE_ANIMATION_VARIANTS = {
  hidden: { x: -40 },
  visible: { x: 0 },
};

const SHADOW_DESKTOP_ANIMATION_VARIANTS = {
  hidden: { x: -40 },
  visible: { x: 12 },
};

const MainLink = ({
  link,
  children,
  className,
}: Pick<SubmenuLink, 'link' | 'children'> & { className: string }) => (
  <Link to={link} className={cx(styles.link, styles.active, className)}>
    <Text variant="m" className={styles.text} textColor="inherited">
      {children}
    </Text>
  </Link>
);

const Submenu = ({ actions, links }: SubmenuProps) => {
  const [leftShadowVisible, setLeftShadowVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollX } = useScroll({ container: containerRef });

  useMotionValueEvent(scrollX, 'change', (latest) => {
    setLeftShadowVisible(latest > 0);
  });

  const mainLink = links.find((link) => link.main);
  const selectedKeys = links.filter((link) => link.active).map((link) => link.link);

  const commonStartShadowProps = {
    animate: leftShadowVisible ? 'visible' : 'hidden',
    initial: 'hidden',
    transition: { type: 'tween' },
  };

  return (
    <header>
      <Container>
        <Stack as="nav" gap={0} type="horizontal" crossAxisAlign="center" mainAxisAlign="stretch">
          {mainLink && <MainLink {...mainLink} className="hideUntilTabletLandscape" />}

          <div className={cx(styles.wrapper, styles.fullWidth)}>
            <motion.div
              {...commonStartShadowProps}
              variants={SHADOW_DESKTOP_ANIMATION_VARIANTS}
              className={cx('hideUntilTabletLandscape', styles.shadowStart)}
            />

            <Stack
              ref={containerRef}
              className={styles.container}
              type="horizontal"
              crossAxisAlign="center"
            >
              <motion.div
                {...commonStartShadowProps}
                variants={SHADOW_MOBILE_ANIMATION_VARIANTS}
                className={cx('hideFromTabletLandscape', styles.shadowStart)}
              />

              {mainLink && <MainLink {...mainLink} className="hideFromTabletLandscape" />}

              <Stack
                gap={0}
                className={cx(styles.fullWidth, styles.listContainer)}
                crossAxisAlign="stretch"
                type="horizontal"
              >
                <TagGroup
                  selectedKeys={selectedKeys}
                  className={styles.listWrapper}
                  listClassName={styles.wrap}
                  aria-label="Blog categories"
                >
                  {links
                    .filter(({ main }) => !main)
                    .map(({ children, link }) => (
                      <Tag
                        id={link}
                        key={link}
                        size="l"
                        textValue={typeof children === 'string' ? children : undefined}
                      >
                        <Link to={link} className={styles.text}>
                          {children}
                        </Link>
                      </Tag>
                    ))}
                </TagGroup>

                <div className={cx(styles.shadow, styles.shadowEnd)} />
              </Stack>
            </Stack>
          </div>

          {actions}
        </Stack>
      </Container>
    </header>
  );
};

export { Submenu };
export type { SubmenuProps, SubmenuLink };
