import { createStaticRoute } from 'modules/prerender/createRoute';
import { NS_CONTENT } from 'constants/i18n';
import { getBlogIndexRouteData } from 'modules/content/services/blog';
import { INITIAL_ENTRIES_PER_PAGE } from 'modules/content/constants';
import type { BlogIndexContext } from 'modules/content/types';

const { getStaticProps: baseGetStaticProps, getStaticPaths } = createStaticRoute({
  path: '/blog',
  translateNamespace: [NS_CONTENT],
});

async function getStaticProps(context: BlogIndexContext) {
  const data = await getBlogIndexRouteData({
    params: context.params!,
    options: {
      entriesPerPage: INITIAL_ENTRIES_PER_PAGE,
    },
  });

  const staticProps = await baseGetStaticProps(context);

  return {
    ...staticProps,
    props: {
      // @ts-expect-error TS(TS2339)
      ...staticProps.props,
      ...data,
    },
  };
}

export { BlogIndexPage as default } from 'modules/content/components/blog/BlogIndexPage';
export { getStaticPaths, getStaticProps };
