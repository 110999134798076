import { Container, Icon, IconButton } from '@carvertical/ui';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { useRouteData } from 'context/RouteDataProvider';
import { NS_COMMON, NS_CONTENT } from 'constants/i18n';
import { type SubmenuLink, Submenu } from '../Submenu';
import type { BlogPathRouteProps, ResolvedBlogCategories } from '../../types';
import { BLOG_ROOT_PATH } from '../../constants';
import { SearchBlock } from './SearchBlock';
import styles from './BlogSubmenu.module.scss';

type BlogSubmenuProps = {
  hideOnScroll?: boolean;
  scrollProgressHidden?: boolean;
};

const SEARCH_BLOCK_ANIMATION_VARIANTS = {
  hidden: { height: 0 },
  visible: { height: 'auto' },
};

const SUBMENU_ANIMATION_VARIANTS = {
  hidden: { y: -56 },
  visible: { y: 0 },
};

const BlogSubmenu = ({ hideOnScroll = false, scrollProgressHidden = true }: BlogSubmenuProps) => {
  const { t } = useTranslation([NS_CONTENT, NS_COMMON]);
  const { categories } = useRouteData<ResolvedBlogCategories>();
  const [submenuVisible, setSubmenuVisible] = useState(true);
  const { query } = useRouter();
  const [searchBlockVisible, setSearchBlockVisible] = useState(false);
  const { scrollY, scrollYProgress } = useScroll();

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (!hideOnScroll) {
      return;
    }

    const previous = scrollY.getPrevious();

    if (latest > 0 && latest > previous && !searchBlockVisible) {
      setSubmenuVisible(false);
    } else {
      setSubmenuVisible(true);
    }
  });

  useEffect(() => {
    setSearchBlockVisible(false);
  }, [setSearchBlockVisible, query]);

  const { path = [] } = query as BlogPathRouteProps;

  const submenuLinks: SubmenuLink[] = (categories || []).map((category) => ({
    children: category.title,
    link: category.pathname,
    active: category.pathname === `/${[BLOG_ROOT_PATH, ...path].join('/')}`,
  }));

  submenuLinks.unshift({
    children: t('common:blogSection.title'),
    link: BLOG_ROOT_PATH,
    main: true,
  });

  if (!submenuLinks.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <motion.div
        variants={SUBMENU_ANIMATION_VARIANTS}
        animate={submenuVisible ? 'visible' : 'hidden'}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className={cx(styles.wrapper, scrollProgressHidden && styles.borderBottom)}
      >
        <Submenu
          links={submenuLinks}
          actions={
            <button
              className={styles.button}
              type="button"
              aria-label={t('searchBlog')}
              onClick={() => setSearchBlockVisible(true)}
            >
              <Icon name="magnifying-glass" />
            </button>
          }
        />

        <AnimatePresence>
          {searchBlockVisible && (
            <motion.div
              transition={{ duration: 0.4, ease: 'easeInOut' }}
              variants={SEARCH_BLOCK_ANIMATION_VARIANTS}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className={styles.searchBlock}
            >
              <Container>
                <div className={styles.searchBlockWrapper}>
                  <IconButton
                    className={styles.closeButton}
                    label={t('closeSearch')}
                    onClick={() => setSearchBlockVisible(false)}
                    icon="close"
                    variant="transparent"
                  />
                  <SearchBlock />
                </div>
              </Container>
            </motion.div>
          )}
        </AnimatePresence>

        {!scrollProgressHidden && (
          <div className={styles.progressWrapper}>
            <motion.div className={styles.progress} style={{ scaleX: scrollYProgress }} />
          </div>
        )}
      </motion.div>
    </div>
  );
};

export { BlogSubmenu };
